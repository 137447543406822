import React from 'react'

import {i18n, isSafariBrowser} from '../../utils'

import {range} from 'lodash-es'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

const LoadingLogo = ({show, hideAnimation, color, text, responsiveSize, isRedShimmer, responsiveLogoSize, size, justifyContent, fontFamily, marginTop, marginLeft, className, padding, showProgressionBar, currentProgressPercentage, dropShadowColor, dropShadowMultiplier, isLoading, reverseShimmer, hideLogo, onClick, marginRight, fastLoading}) => {
  const baseDropShadow = `0px 0px 1.5px ${!!(dropShadowColor) ? dropShadowColor : "#353F6A"}`
  const baseSvgDropShadow = `0px 0px 1px ${!!(dropShadowColor) ? dropShadowColor : "#353F6A"}`
  const textShadow = range(!!(dropShadowMultiplier) ? dropShadowMultiplier : 0).map(() => (baseDropShadow)).join(",")
  const svgDropShadow = range(!!(dropShadowMultiplier) ? Math.floor(dropShadowMultiplier - 1) : 0).map(() => (`drop-shadow(${baseSvgDropShadow})`)).join(" ")

  const shimmerClassName = isRedShimmer ? "shimmer-red" : "shimmer"

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames="fade-in-300"
      unmountOnExit
    >
      <div style={{marginLeft: marginLeft || 0, marginRight: marginRight || 0, cursor: !!(onClick) ? "pointer" : "default"}} onClick={() => onClick && onClick()}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            timeout={200}
            classNames={"fade-in-200-out-0"}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            unmountOnExit
            key={text}
          >
            <div className={hideAnimation ? "" : "loading-pulse-animation " + (className ? className : "")} style={{marginTop: (marginTop !== undefined) ? marginTop : 20, width: "100%", paddingTop: padding || 5, paddingBottom: padding || 5, display: "flex", alignItems: "center", justifyContent: justifyContent || "center"}}>
              {!(hideLogo) && (
                <div className={(isLoading ? (reverseShimmer ? `${shimmerClassName} shimmer-reverse` : shimmerClassName) : "")} style={{display: "inline-block", height: responsiveLogoSize || size * 0.9 || 40, width: responsiveLogoSize || size * 0.9 || 40, marginRight: marginRight || (responsiveSize && `calc(${responsiveSize} / 3.0)` || size * 0.15), filter: svgDropShadow}}>
                  <svg className={"authentique-svg"} width="628px" height="635px" viewBox="0 0 628 635" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><polygon id="path-1" points="0 0 628 0 628 635 0 635"></polygon><polygon id="path-3" points="377.86 -1.42108547e-14 1032.33 377.86 654.47 1032.33 0 654.47"></polygon></defs><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="lgcy-logo"><mask id="mask-2" fill="white"><use xlinkHref="#path-1"></use></mask><g id="Path"></g><g id="Clipped" mask="url(#mask-2)"><g transform="translate(-129.160000, -259.164000)"><mask id="mask-4" fill="white"><use xlinkHref="#path-3"></use></mask><g id="Path" stroke="none" fill="none"></g><path d="M690.62,590.384 C670.874,617.161 654.265,631.876 634.522,647.814 C634.522,647.814 679.717,727.732 660.288,785.924 C644.847,832.151 577.683,835.955 513.908,751.834 C447.103,663.725 425.404,565.954 452.185,484.564 C401.705,459.337 376.06,465.97 376.06,465.97 C376.06,465.97 333.423,632.34 455.888,803.44 C575.268,970.24 837.838,909 690.618,590.39 L690.62,590.384 Z M659.343,336.844 C729.105,360.735 637.527,603.354 471.893,634.164 C471.893,634.164 478.2172,673.891 502.127,705.582 C502.127,705.582 647.197,672.691 727.357,500.422 C807.545,328.152 704.572,266.292 650.498,260.612 C596.416,254.8932 499.628,277.413 426.158,369.792 C456.955,375.4717 470.349,380.444 506.388,398.269 C506.388,398.265094 583.634,310.921 659.348,336.851 L659.343,336.844 Z M204.133,541.644 C205.133,436.934 425.453,385.144 577.153,561.753 C577.153,561.753 602.383,541.312 626.645,504.191 C626.645,504.191 475.165,346.421 296.595,376.641 C176.175,397.016 105.375,494.821 137.715,594.761 C163.957,675.995 262.565,728.961 389.035,722.221 C377.043,694.729 370.555,678.084 364.242,647.678 C364.245907,647.681906 203.112,646.3811 204.132,541.638 L204.133,541.644 Z" id="Shape" stroke="none" fill={color} fillRule="nonzero" mask="url(#mask-4)"></path></g></g></g></g></svg>
                </div>
              )}
              {!!(text) && (
                <div className={isLoading ? (reverseShimmer ? `${shimmerClassName} shimmer-reverse` : shimmerClassName) : ""} style={{display: "inline-block", WebkitTextStroke: isSafariBrowser ? 0.8 : "unset", letterSpacing: "0.1em", color: color, fontFamily: fontFamily || "Montserrat", fontSize: responsiveSize || (size / 1.5 || 20), lineHeight: responsiveSize || (size / 1.5 || 20), textShadow: textShadow}}>
                  {text}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <div style={{marginTop: showProgressionBar ? (responsiveSize && `calc(${responsiveSize} / 5.0)` || size * 0.15) : 0}}>
          <div style={{display: "flex", justifyContent: justifyContent || "center"}}>
            <div 
              style={{marginLeft: 0, backgroundColor: (color === "#bbb" ? "#bbb" : "#9a9fb4"), boxShadow: baseDropShadow, height: 4, borderRadius: 2, overflow: "hidden", width: 100, opacity: showProgressionBar ? 1.0 : 0.0, display: showProgressionBar ? "" : "none", transition: "opacity linear 300ms"}}
            >
              <div style={{backgroundColor: (color === "#bbb" ? "#353F6A" : "white"), height: "100%", width: currentProgressPercentage, transition: "width linear 200ms"}} />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default LoadingLogo;