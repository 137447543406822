import React from 'react';
import {PaymentElement, CardElement, PayButtonElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import ProgramResultsPanel from './ProgramResultsPanel'
import LoadingLogo from './LoadingLogo'

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import {
  StripePublishableKey,
  NeuroFitUniversalAppStoreLink,
  getFormattedDateDaysFromNow,
  getCsrfTokenFromCookies,
  INVITE_CODE_LOCAL_STORAGE_KEY,
  subdomainUrls,
  recordFunnelEventResultGA,
  recordPixelEventIfAvailable,
  recordLinkClickAndRedirect,
  AmazonNeuroFitBookUrl,
  sanitizeEmailAddress,
} from '../../utils'

import {
  REQUEST_UNSTARTED,
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../../constants/requestStates'

import {
  LockSvg,
  StripeSvg,
  ErrorSvg,
} from '../../constants/svgs'

import {
  GA_LABEL_COURSE_PURCHASE_TRIGGERED,
  GA_LABEL_COURSE_PURCHASE_COMPLETED,
} from '../../constants/gaEventLabels'

import agent from '../../agent'

const NEUROFIT_FONTS = [
  {
    src: 'url(https://neurofit.app/fonts/FuturaLT-Book.woff2)',
    family: 'Futura Book',
    style: 'normal'
  }
]

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isComplete: false,
      isProcessingPayment: false,
      promotionCodeRequestState: REQUEST_UNSTARTED,
      nameOnCard: "",
      emailAddress: "",
      clientSecretResult: {},
      clientSecret: "",
      selectedProductName: "Embodied CEO Program",
      selectedProductPrice: 19700,
      selectedBundle: false,
      paymentStatus: REQUEST_UNSTARTED,
      paymentFailureMessage: "",
      paymentRequest: undefined,
      paymentRequestLoaded: false,
      showPayButton: false
    };
  }

  async componentDidMount() {
    await this.handleRetrieveClientSecret()
  }

  handleRetrieveClientSecret = async (promotionCode) => {

    try {
      // Fetch Payment Intent.
      let clientSecretResult = await agent.EmbodiedCEOBackend.createCertificationPaymentIntent({})

      if (clientSecretResult.success) {
        this.setState({clientSecretResult})
        this.setState({clientSecretResult, clientSecret: clientSecretResult.client_secret, selectedProductName: "Embodied CEO", selectedProductPrice: clientSecretResult.price_usd_cents})
      }
    } catch (err) {
      
    }
  }

  handlePayButtonCheckout = async (ev) => {
    const {
      stripe,
      elements,
      onShowSuccessMessage,
      onShowErrorMessage
    } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({isProcessingPayment: true, paymentStatus: REQUEST_FETCHING})

    const productId = "embodied_ceo"
    recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_TRIGGERED)
    recordPixelEventIfAvailable("AddPaymentInfo", productId)

    const {client_secret: clientSecret} = await agent.EmbodiedCEOBackend.createProgramPaymentIntent()

    // Confirm the PaymentIntent using the details collected by the Pay Button Element
    const {error, paymentIntent} = await stripe.confirmPayment({
      // `elements` instance used to create the Pay Button Element
      elements,
      // `clientSecret` from the created PaymentIntent
      clientSecret,
      confirmParams: {
        return_url: "https://embodiedceo.life",
      },
      redirect: "if_required"
    });

    if (error) {
      // Show an error message and close
      // the payment interface.
      console.log(error.message);
      onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})

    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_COMPLETED)
          recordPixelEventIfAvailable("Purchase", productId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
          onShowSuccessMessage(`Thank you for purchasing the Embodied CEO Program. In a few moments, you'll receive an email confirmation with details to access your content.`)
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_COMPLETED)
        recordPixelEventIfAvailable("Purchase", productId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        onShowSuccessMessage(`Thank you for purchasing the Embodied CEO Program. In a few moments, you'll receive an email confirmation with details to access your content.`)
      }
    }
  }
  
  handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    const {
      stripe,
      elements,
      onShowSuccessMessage,
      onShowErrorMessage,
      isCourse,
      isBookPage,
    } = this.props;

    const {
      nameOnCard,
      emailAddress,
      selectedBundle,
    } = this.state

    const productId = "embodied_ceo"

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    this.setState({isProcessingPayment: true, paymentStatus: REQUEST_FETCHING})

    recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_TRIGGERED)
    recordPixelEventIfAvailable("AddPaymentInfo", productId)

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const {client_secret: clientSecret} = await agent.EmbodiedCEOBackend.createProgramPaymentIntent()

    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
          email: emailAddress,
        }
      },
      receipt_email: emailAddress
    });

    if (error) {
      console.log(error.message);
      onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
      this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
      return;
    } else {
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const {error} = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          console.log(error.message);
          onShowErrorMessage("Something went wrong using this payment method. Please try a different card.")
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_ERROR, paymentFailureMessage: `Something went wrong using this card: ${error.message} Please try a different card.`})
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
          console.log(`Purchase Succeeded!`);
          recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_COMPLETED)
          recordPixelEventIfAvailable("Purchase", productId)
          this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
          onShowSuccessMessage(`Thank you for purchasing the Embodied CEO Program. In a few moments, you'll receive an email confirmation with details to access your content.`)
        }
      } else {
        // The payment has succeeded.
        console.log(`Purchase Succeeded!`);
        recordFunnelEventResultGA(GA_LABEL_COURSE_PURCHASE_COMPLETED)
        recordPixelEventIfAvailable("Purchase", productId)
        this.setState({isProcessingPayment: false, paymentStatus: REQUEST_SUCCESS})
        onShowSuccessMessage(`Thank you for purchasing the Embodied CEO Program. In a few moments, you'll receive an email confirmation with details to access your content.`)
      }
    }
  }

  handleUpdateNameOnCard = (nameOnCard) => {
    this.setState({nameOnCard})
  }

  resetPromotionCodeRequestState = (promotionCode) => {
    this.setState({promotionCodeRequestState: REQUEST_UNSTARTED})
  }

  togglePromotionCodeFocus = isPromotionCodeFocused => {
    this.setState({isPromotionCodeFocused})
  }

  handleUpdateEmailAddress = (emailAddress) => {
    this.setState({emailAddress: sanitizeEmailAddress(emailAddress)})
  }

  handleOnReady = async () => {
    this.setState({ready: true})
  }

  handlePayButtonReady = ({availablePaymentMethods}) => {
    if (!!availablePaymentMethods) {
      this.setState({showPayButton: true})
    }
  }

  handlePayButtonClick = ({resolve}) => {
    const options = {
      emailRequired: true,
    };

    resolve(options);
  }

  handleUpdatedCompletionStatus = isComplete => {
    this.setState({isComplete})
  }

  render() {
    const {
      stripe,
      onClickSubmitAccessCode,
      onApplyPromotionCode,
      showFreeAccessCode,
      numberOfSeats,
      isNative,
      billingInterval,
      intervalPrice,
    } = this.props;
    const {ready, clientSecret, isComplete, promotionCodeRequestState, selectedProductPrice, selectedBundle, isProcessingPayment, paymentRequest, paymentRequestLoaded, paymentStatus, paymentFailureMessage, nameOnCard, emailAddress, showPayButton} = this.state;

    const readyForCheckout = isComplete && !!(nameOnCard) && !!(emailAddress)

    const checkoutButtonText = isProcessingPayment ? "PURCHASING..." :
          ((paymentStatus === REQUEST_SUCCESS) ? "PURCHASE COMPLETED" : "BUY NOW")

    const successMessage = `Thank you for purchasing the Embodied CEO Program. In a few moments, you'll receive an email confirmation with details to access your content. If you don't see the email, check your spam folder.`

    const fullCheckoutFormLoaded = ready

    return (
      <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{maxWidth: 500, color: "#000"}}>
          <div
            hidden={fullCheckoutFormLoaded}
            className={"loading-pulse-animation"}
            style={{fontFamily: "Futura Book", textAlign: "center", marginTop: "5vh", fontSize: "min(4vh, 30px)", color: "#000000"}}
          >
            <LoadingLogo
              show={true}
              hideAnimation={true}
              responsiveSize={"min(7vw, 35px)"}
              marginTop={0}
              color={"black"}
              fontFamily={"Futura Book"}
              text={"Loading Checkout..."}
              showProgressionBar={false}
            />
          </div>
          <div style={{backgroundColor: "transparent"}}>
            <div>
              <div style={{display: "flex", justifyContent: "left"}}>
                <div style={{fontFamily: "Futura Book", fontSize: "min(6vw, 25px)", color: "#777", marginTop: 10}}>
                  {`$${(selectedProductPrice / 100.0).toFixed(0)} USD`}
                </div>
              </div>
            </div>
            <div style={{marginRight: "0.2em", marginTop: 10, marginBottom: "min(24px, max(3vw, 16px))", fontSize: "min(4.5vw, 18px)", fontFamily: "Futura Light"}}>
              <span>
                {"After enrollment, you'll receive an email receipt with details for immediate program access."}
              </span>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div style={{marginTop: "10px", display: showPayButton ? "block" : "none", opacity: showPayButton ? 1.0 : 0.0, transition: "opacity 150ms linear"}}>
                <PayButtonElement onReady={({availablePaymentMethods}) => this.handlePayButtonReady({availablePaymentMethods})} options={{buttonHeight: 40}} onClick={({resolve}) => this.handlePayButtonClick({resolve})} onConfirm={() => this.handlePayButtonCheckout()} />
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Futura Book", fontSize: "min(4.5vw, 18px)", margin: "15px 0px", lineHeight: "min(6vw, 26px)", color: "#a5a8b0"}}>
                  <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                  <span style={{flex: "0 0 auto", padding: "0px 0.4em"}}>{"OR"}</span>
                  <div style={{height: "1px", width: "50%", flex: "2 1 auto", backgroundColor: "#a5a8b0"}}></div>
                </div>
              </div>
              <div style={{margin: "10px 0px"}}>
                <input
                  type="text"
                  className={"application-input application-input-checkout"}
                  placeholder={"Name on card"}
                  autoCapitalize="words"
                  autoCorrect="false"
                  autoComplete="cc-name"
                  spellCheck={false}
                  value={nameOnCard}
                  disabled={isProcessingPayment}
                  onChange={(e) => this.handleUpdateNameOnCard(e.target.value)}
                  onPaste={(e) => this.handleUpdateNameOnCard(e.target.value)}
                />
              </div>
              <div style={{margin: "10px 0px"}}>
                <input
                  type="text"
                  className={"application-input application-input-checkout"}
                  placeholder={"Email address"}
                  autoCapitalize="words"
                  autoCorrect="false"
                  autoComplete="email"
                  spellCheck={false}
                  value={emailAddress}
                  disabled={isProcessingPayment}
                  onChange={(e) => this.handleUpdateEmailAddress(e.target.value)}
                  onPaste={(e) => this.handleUpdateEmailAddress(e.target.value)}
                />
              </div>
              <div className={"application-input"} style={{height: 22, padding: "12px 8px", textRendering: "geometricPrecision"}}>
                <CardElement
                  onReady={() => this.handleOnReady()}
                  onChange={e => {
                    this.handleUpdatedCompletionStatus(e.complete)
                  }}
                  options={{
                    style: {
                      base: {
                        fontSize: "18px",
                        fontFamily: 'Futura Book, sans-serif',
                        fontSmoothing: "antialiased",
                        color: '#000000',
                        '::placeholder': {
                          color: '#777',
                        },
                        backgroundColor: "#FFFFFF"
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    }
                  }}
                />
              </div>
              <button
                type="submit"
                style={{display: "flex", alignItems: "top", justifyContent: "center",  width: "100%", fontWeight: "normal", textAlign: "center", fontFamily: "Futura Book", fontSize: "18px", lineHeight: "18px", marginTop: 10, color: (isProcessingPayment || (paymentStatus === REQUEST_SUCCESS)) ? "rgba(255, 255, 255, 0.4)" : "#ffffff", backgroundColor: "#000000", transition: "color 250ms linear", padding: "11px 15px", border: "none"}}
                disabled={!stripe || !readyForCheckout || (paymentStatus === REQUEST_SUCCESS)}
              >
                {checkoutButtonText}
              </button>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  timeout={500}
                  classNames={"fade-in"}
                  unmountOnExit
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  key={paymentStatus}
                >
                  <div>
                    {(paymentStatus === REQUEST_UNSTARTED) && (
                      <div style={{fontSize: "min(4vw, 18px)", color: "#a5a8b0", marginTop: "2vh"}}>
                        <span style={{marginRight: "0.2em"}}>{`By purchasing the Embodied CEO Program, you agree to our platform`}</span>
                        <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#terms`} target="_blank" rel="noopener noreferrer nofollow">{"Terms"}</a>
                        <span style={{marginRight: "0.2em"}}>{" & "}</span>
                        <a style={{color: "#a5a8b0", fontFamily: "Futura Book"}} href={`${subdomainUrls.baseLegalUrl}/#privacy`} target="_blank" rel="noopener noreferrer nofollow">{"Privacy Policy"}</a>
                        <span>{". Thank you!"}</span>
                      </div>
                    )}
                    {(paymentStatus === REQUEST_ERROR) && (
                      <ProgramResultsPanel
                        backgroundColor={"#ffb4b4"}
                        boxShadow={"0px 0px 1px 1px red"}
                        marginTop={"2vh"}
                        title={"PAYMENT ERROR"}
                        description={paymentFailureMessage}
                      />
                    )}
                    {(paymentStatus === REQUEST_SUCCESS) && (
                      <div style={{marginTop: "2vh", padding: "max(3vw, 16px)", backgroundColor: "#ffffff", boxShadow: "0px 1px 1.5px 0.5px #dddddd"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                          <div style={{display: "inline-block", fontFamily: "Futura Book", fontSize: "min(5vw, 22px)"}}>
                            {"PURCHASE SUCCESSFUL"}
                          </div>
                        </div>
                        <div style={{paddingTop: "max(2vw, 10px)"}}>
                          <span style={{fontFamily: "Futura Light", fontSize: "min(4vw, 18px)"}}>
                            {successMessage}
                          </span>
                        </div>
                      </div>
                    )}
                    {(paymentStatus === REQUEST_FETCHING) && (
                      <ProgramResultsPanel
                        backgroundColor={"#FFFFFF"}
                        marginTop={"2vh"}
                        title={"PROCESSING PAYMENT"}
                        description={"Your payment is being processed securely through Stripe..."}
                      />
                    )}
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class StripeCheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    const {loadStripe} = require('@stripe/stripe-js')
    const stripePromise = loadStripe(StripePublishableKey, {
      betas: [
        "pay_button_element_beta_1",
        "elements_enable_deferred_intent_beta_1",
      ],
    });

    this.state = {
      stripePromise
    }
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
  }

  render() {
    const {stripePromise} = this.state

  const options = {
    mode: 'payment',
    amount: 19700,
    currency: 'usd',
    // Customizable with appearance API.
    appearance: {/*...*/},
    fonts: NEUROFIT_FONTS
  };

    const {onClickSubmitAccessCode,  onShowSuccessMessage, onApplyPromotionCode, onShowErrorMessage, showFreeAccessCode, numberOfSeats, isNative, adminAccountData, billingInterval, intervalPrice, isCourse, isBookPage, isUpdateSubscription} = this.props
    return (
      <Elements options={options} stripe={stripePromise}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <CheckoutForm
              elements={elements}
              stripe={stripe}
              showFreeAccessCode={showFreeAccessCode}
              isCourse={isCourse}
              isBookPage={isBookPage}
              onApplyPromotionCode={onApplyPromotionCode}
              onClickSubmitAccessCode={onClickSubmitAccessCode}
              onShowSuccessMessage={onShowSuccessMessage}
              onShowErrorMessage={onShowErrorMessage}
              isNative={isNative}
              numberOfSeats={numberOfSeats}
              adminAccountData={adminAccountData}
              billingInterval={billingInterval}
              intervalPrice={intervalPrice}
            />
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
};

export default StripeCheckoutForm;