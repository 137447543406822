import React from 'react'

import {
  AddCircleSvg,
  RemoveCircleSvg,
} from '../../constants/svgs'

const FaqSection = ({
  question,
  answer,
  showAnswer,
  onToggleShowAnswer,
  marginTop,
  marginBottom,
  backgroundColor,
  italicAnswer,
  color
}) => {
  return (
    <button onClick={() => onToggleShowAnswer()} style={{marginTop, marginBottom, border: "none", width: "100%", backgroundColor: backgroundColor || "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", padding: "min(4vw, 16px)"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <div style={{display: "inline-block", height: "min(4.5vw, 18px)", width: "min(4.5vw, 18px)"}}>
          {showAnswer ? (
            <RemoveCircleSvg size={"min(4.5vw, 18px)"} color={"#000000"} />
          ) : (
            <AddCircleSvg  size={"min(4.5vw, 18px)"} color={"#000000"} />
          )}
        </div>
        <span style={{color: color || "#000000", fontSize: "min(5.5vw, 22px)", marginLeft: 15, textAlign: "left", fontFamily: "Futura Book"}}>
          {question}
        </span>
      </div>
      <div style={{display: showAnswer ? "block" : "none", fontStyle: italicAnswer ? "italic" : "", color: color || "#000000", fontSize: "min(5vw, 20px)", marginTop: showAnswer ? "min(4vw, 16px)" : "0px", textAlign: "left", fontFamily: "Futura Light"}}>
        {answer}
      </div>
    </button>
  );
}

export default FaqSection;