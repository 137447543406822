import React from 'react';
import queryString from 'query-string'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export const getTimeZoneOffset = () => ({
  name: dayjs.tz.guess(),
  offset: dayjs().tz(dayjs.tz.guess()).format('Z')
})

export const isProduction = (process.env.NODE_ENV === 'production')

export const StripePublishableKey = (isProduction) ? 
"pk_live_51LK7yaJv6nSiqyNcavyFRxoyYm6OCKQlZF0U6UF423ccOiG4NYnbxhGH96LhnSU88TOuZekH0VSdcxR1vRSubP1g00B3ADHJNx" :
"pk_test_51LK7yaJv6nSiqyNc8sPaLLTiRYnb19Qw3i76ZHbjYdnArggu7BRPPxftAYyneU4lqHJt2RuRrEuiMjZYCHJVsc7h003980UO5c"

// Google Analytics Utils
export const initializeGA = () => {
  if (isProduction) {
    // ReactGA.initialize('UA-172409349-2');
  }
}

export const pageViewGA = () => {
  if (isProduction) {
    // ReactGA.pageview('/');
  }
}

export const recordEventGA = (eventType, eventName, amplitudeContext=undefined) => {
  if (isProduction) {
    // ReactGA.event({
    //   category: eventType,
    //   action: eventName,
    //   label: "Analytics Event",
    //   value: 1
    // });
    // Log all events to Amplitude as well for better funnel analysis.
    // window.amplitude.getInstance().logEvent(eventType + ": " + eventName, amplitudeContext)
  }
}

export const recordFunnelEventResultGA = (eventName, properties) => {
  try {
    if (isProduction) {
      recordEventGA("Event Result", eventName, properties)
    }
  } catch (err) {
    console.error(err)
  }
}

export const recordStoryReadEvent = (storyKey) => {
  return recordEventGA("Story Read", storyKey)
}

export const recordLinkClickGA = (eventName) => {
  return recordEventGA("Link Click", eventName);
}

export const recordVibeCheckFunnelEventGA = (eventName, amplitudeContext=undefined) => {
  return recordEventGA("Vibe Check Funnel", eventName, amplitudeContext);
}

export const recordSessionEventGA = (sessionType) => {
  return recordEventGA("Session", sessionType)
}

export const configureAmplitudeUser = ({isSubscribed=false, page=undefined, language='en'}) => {
  // let identify = new window.amplitude.Identify()
  //   .set('is_subscribed', isSubscribed)
  //   .set('page', page)
  //   .set('language', language)
  // window.amplitude.getInstance().identify(identify)

}

let ENABLE_PIXEL = false;
let ReactPixel;

export const initializePixelIfAvailable = async () => {
  try {
    if (isProduction) {
      const {name} = getTimeZoneOffset()
      ENABLE_PIXEL = !name.startsWith("Europe")
      // GDPR-Compliant
      if (ENABLE_PIXEL) {
        ReactPixel = require('react-facebook-pixel').default
        ReactPixel.init("2022945354569015", {}, {autoConfig: true, debug: false});
        ReactPixel.pageView(); // For tracking page view
      }
    }
  } catch (err) {
    console.error(`Error Loading Pixel: ${err}`)
  }
}

// TODO: Record Events: Lead, AddPaymentInfo, InitiateCheckout, Purchase
export const recordPixelEventIfAvailable = (eventName, productId) => {
  const productPricesUsd = {
    "embodied_ceo": 47.00,
  }
  const productValueUsd = productPricesUsd[productId] || 47.00
  if (isProduction && ENABLE_PIXEL && !!(ReactPixel)) {
    ReactPixel.track(eventName, {currency: "USD", value: productValueUsd})
  }
}


// User Agent Utils
export const getUserAgent = () => {
  return navigator.userAgent || navigator.vendor || window.opera;
}

export const isIOSDevice = (() => {
  return (/iPad|iPhone|iPod/.test(getUserAgent()) && !window.MSStream)
})()

export const isIOSSafari = (() => {
  if (!isIOSDevice) {
    return false
  }
  const ua = window.navigator.userAgent;
  return (!!ua.match(/WebKit/i) && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i))
})()

export const isSafariBrowser = (() => {
const ua = window.navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || !!(window.safari) || isIOSDevice
})();

export const isAndroidDevice = () => {
  return (/android/i.test(getUserAgent()))
}

export const runningAsApp = () => {
  if (isIOSDevice) {
    return (window.navigator.standalone === true)
  } else {
    // Android
    return window.matchMedia('(display-mode: standalone)').matches
  }
}

export const isIOSStandalone = () => {
  return isIOSDevice() && (window.navigator.standalone === true)
}


export const baseDomain = "embodiedceo.life"
export const baseLegalDomain = "legal.neurofit.app"
export const baseAppHost = isProduction ? baseDomain : "localhost:4103"

export const subdomainHosts = {
  baseAppHost,
}



const httpPrefix = isProduction ? "https://" : "http://"

export const baseAppUrl = httpPrefix + baseAppHost
export const baseLegalUrl = `https://${baseLegalDomain}`

export const subdomainUrls = {
  baseAppUrl,
  baseLegalUrl,
}

export const joinWithAnd = arr => {
  const temp = arr.join(', ')
  if (arr.length > 2) {
    return temp.replace(/,(?!.*,)/gmi, ', and');
  } else {
    return temp.replace(/,(?!.*,)/gmi, ' and');
  }
}

export const copyToClipboard = (function(){
  let textToCopy;
  let clearSelection = false;
  let didCopy = false;
  document.addEventListener('copy', e => {
    if (textToCopy !== undefined) {
      try {
        e.clipboardData.setData('text/plain', textToCopy);
        e.preventDefault();
        didCopy = true;
      } finally {
        textToCopy = undefined;
      }
    }
  });
  return function(text) {
    textToCopy = text;
    if (!document.queryCommandEnabled('copy')) {
      // See: https://bugs.webkit.org/show_bug.cgi?id=156529
      const sel = document.getSelection();
      const range = document.createRange();
      range.selectNodeContents(document.body);
      sel.addRange(range);
      clearSelection = true;

    }
    didCopy = false;
    document.execCommand('copy');
    if (clearSelection) {
      clearSelection = false;
      document.getSelection().removeAllRanges();
    }
    return didCopy;
  };
})();

export const toggleBodyScroll = (enable) => {
    if (enable) {
      document.body.classList.remove("noscroll")
    } else {
      document.body.classList.add("noscroll")
    }
}

export const hideLoadingScreen = () => {
  let loadingScreenElem = document.getElementById("loading-screen")
  console.log(loadingScreenElem)
  console.log("LOADING SCREEN^")
  if (!!(loadingScreenElem)) {
    console.log("FOUND LOADING SCREEN")
    console.log(loadingScreenElem)
    loadingScreenElem.parentNode.removeChild(loadingScreenElem);
  }
}

export const MasterclassVideoUrl = "https://masterclass.embodiedceo.life/"
export const LorenIntroVideoUrl = "https://vimeo.com/725636309/d0f986205e"
export const AndrewIntroVideoUrl = "https://vimeo.com/725630569/bb7ece505a"
export const LorenInstagramAccountUrl = "https://www.instagram.com/lorentrlin/"
export const NeuroFitHomePageUrl = "https://neurofit.app"


/////////////
// Cookies //
/////////////
const CSRF_TOKEN_KEY = 'csrf_token'
const COOKIE_MISSING = "_missing"
const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for(let i=0;i < ca.length;i++) {
    let c = ca[i];
    while (c.charAt(0)===' ') c = c.substring(1,c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }
  return COOKIE_MISSING;
}

export const getCsrfTokenFromCookies = () => getCookie(CSRF_TOKEN_KEY)
export const getCsrfTokenCookieIsPresent = () => {
  const c = getCookie(CSRF_TOKEN_KEY)
  return (c !== COOKIE_MISSING) && !!(c)
}

export const sanitizeEmailAddress = emailAddress => emailAddress.toLowerCase().replace(/\s+/g, '')

export const MaxTextResponseLength = 1000