import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';

import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { polyfill } from "seamless-scroll-polyfill";

import App from './components/App';

const history = require("history").createBrowserHistory();

require('./style.css');

polyfill();

ReactDOM.render((
    <Router history={history}>
      <Switch>
        <Route path="/" component={App} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Router>

), document.getElementById('root'));