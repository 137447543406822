import {
  baseDomain,
  baseAppUrl
} from './utils'

const axios = require('axios')
const promiseRetry = require('promise-retry');


const CSRF_TOKEN_HEADER = 'X-Csrf-Token'

const BACKEND_BASE_URL = (process.env.NODE_ENV === 'production') ?
  `https://api.${baseDomain}`
  : 'http://localhost:8003';


const requests = {
  get: (fullPath, options={retries:2}) => {
    return promiseRetry({retries: options.retries}, (retry, number) => {
      return axios.request({
        method: 'GET',
        url: fullPath,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: 'X-Csrf-Token'
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err.response.data;
        });
    });
  },
  post: (fullPath, body, options={retries:2}) => {
    return promiseRetry({retries: options.retries}, (retry, number) => {
      return axios.request({
        method: 'POST',
        url: fullPath,
        data: body,
        responseType: 'json',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        xsrfHeaderName: 'X-Csrf-Token'
      })
        .then(result => {
          return result.data
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err.response ? err.response.data : {error_code: "default_error_message"};
        });
    });
  },
  getMetadata: (fullPath, options={retries:2, useCache:false}) => {
    // Note: these headers ensure we always fetch the most up-to-date
    // version of the requested JSON file. 
    let cacheConfig = {}
    if (!(options.useCache)) {
      cacheConfig["cache"] = "no-cache"
    }

    return promiseRetry({retries: options.retries}, (retry, number) => {
      return fetch(fullPath, cacheConfig).then(
        function (result) { // success handler
          if (!(result.ok)) {
            throw new Error("Invalid Metadata Response")
          }
          return result.json()
        },
        function (err) { // failure handler
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err;

        }
     ).catch((err) => {
          if (err.statusCode && err.statusCode >= 500) {
            retry(err);
          }
          throw err;
        });
    });
  },
};

const EmbodiedCEOBackend = {
  // submitApplication: (requestBody) =>
  //   Promise.resolve({"success": true}),
  submitApplication: (requestBody) =>
    requests.post(`${BACKEND_BASE_URL}/application`, requestBody, {retries:2}),
  submitPrelaunchEventApplication: (requestBody) =>
    requests.post(`${BACKEND_BASE_URL}/application/prelaunch`, requestBody, {retries:2}),
  createProgramPaymentIntent: () =>
    requests.post(`${BACKEND_BASE_URL}/payment-intent`, {}, {retries:2}),
  // Mocked for now. Todo: Implement Actual Endpoints.
  // getSpotifyTrackMetadata: ({vibe_check_url}) =>
  //   Promise.resolve({"artist_name": "Foals", "artist_profile_image_url": "https://i.scdn.co/image/ab6761610000f1782c64dee66a7e41971458a4a8", "album_image_url": "https://i.scdn.co/image/ab67616d00001e02bece64c47957bcf1da2e40d3", "track_name": "Neptune"}),
  // analyzeTrackVibration: ({artist_name, track_name}) =>
  //   Promise.resolve({"share_url": "https://checkthevi.be/s/xxxx", "top_emotions": [{"name": "pessimistic", "probability": 0.17895061812137425}, {"name": "despair", "probability": 0.15538306628695353}, {"name": "despondent", "probability": 0.11608109767406362}], "consciousness": {"level_of_consciousness": 571, "metadata": {"display_name": "Joy", "name": "joy", "description": "At Joy, miracles occur as a constant unfolding of divinity.\n- The spiritual ego is avoided by realizing that miracles are a gift from the Self - we are here to channel love. The gratitude in joy replaces any pride of accomplishment.\n- Saints, healers, advanced spiritual students + devoted self-help groups reside at the level of Joy.\n- There is enormous patience and persistence of positivity when faced with prolonged adversity\n - It is accompanied by a prolonged, compassionate open visual gaze that induces peace in others.", "transcendence": "- Notice that all fear is illusion, due merely to the clinging to beliefs and perceptions.\n- One may need the help of others to accept the required changes. It may take considerable courage + patience, as well as conviction to adjust.\n- Major leap in consciousness here.\n- Inner + outer changes turn out to be much more major than anticipated.\n- One must let go of the state of ecstacy which is often overwhelming - when surrendered, it becomes replaced by a timeless sense of peace."}, "top_levels_of_consciousness": [{"name": "joy", "probability": 0.9133598800869078}, {"name": "acceptance", "probability": 0.05482437632669269}, {"name": "desire", "probability": 0.005226660085071903}, {"name": "illumination", "probability": 0.0035681402955785872}]}}),
//   getCachedVibecheckResult: ({short_code}) =>
//     Promise.resolve({"spotify_track_metadata": {"artist_name": "KIDS SEE GHOSTS", "track_name": "Cudi Montage", "artist_profile_image_url": "https://i.scdn.co/image/ab6761610000f17822c693c66a5494d88194d543", "album_image_url": "https://i.scdn.co/image/ab67616d00001e02013c00ee367dd85396f79c82"}, "vibe_check_metadata": {"top_emotions": [{"name": "morose", "probability": 0.261810642301369}, {"name": "discouraged", "probability": 0.09569138576620793}, {"name": "", "probability": 0}], "consciousness": {"level_of_consciousness": 645, "name": "illumination", "top_levels_of_consciousness": [{"name": "illumination", "probability": 0.8596324019864896}, {"name": "self-realization", "probability": 0.10135408466866556}, {"name": "joy", "probability": 0.029402832525682956}, {"name": "acceptance", "probability": 0.004956486675146749}, {"name": "unconditional love", "probability": 0.004654194144015113}]}, "share_url": "https://checkthevi.be/s/s8o9slPsRJKJ-RtEnkzrw73a99yLSsvHZkUFXeLy"}, "created_at": 1633804080})
}


export default {
  EmbodiedCEOBackend,
};
