import React from 'react';


import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { range } from 'lodash-es';
import LoadingLogo from './common/LoadingLogo'
import TopErrorMessage from './common/TopErrorMessage'
import ErrorPanel from './common/ErrorPanel'
import BottomSuccessMessage from './common/BottomSuccessMessage'

import FaqSection from './common/FaqSection'
import StripeCheckoutForm from './common/StripeCheckoutForm'

import {
  GA_LABEL_LOADED_LANDING_PAGE,
} from '../constants/gaEventLabels'

import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
  REQUEST_UNSTARTED,
  REQUEST_ERROR,
} from '../constants/requestStates';

import {
  NeuroFitLogoSvg,
} from '../constants/svgs'

import smoothscroll from 'smoothscroll-polyfill';

import {
  baseDomain,
  copyToClipboard,
  recordLinkClickGA,
  recordVibeCheckFunnelEventGA,
  language,
  isIOSDevice,
  MasterclassVideoUrl,
  LorenIntroVideoUrl,
  AndrewIntroVideoUrl,
  NeuroFitHomePageUrl,
  LorenInstagramAccountUrl,
  initializePixelIfAvailable,
  hideLoadingScreen,
} from '../utils'

const safeAreaInsets = require('safe-area-insets')

const containerStyle = {textAlign: "left", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}


const CourseOutlineBulletPoint = ({text}) => (
  <div style={{display: "flex", alignItems: "top", justifyContent: "left", marginTop: "0.4em", padding: "0px 2vh"}}>
    <div style={{display: "inline-block", fontSize: "min(4vw, 18px)", lineHeight: "min(6vw, 26px)", fontFamily: "Futura Light", marginRight: "0.6em"}}>
      {"•"}
    </div>
    <div style={{fontFamily: "Futura Light", fontSize: "min(5vw, 20px)", lineHeight: "min(6vw, 26px)", textAlign: "left"}}>
      {text}
    </div>
  </div>
)

const CourseOutlineBulletPoints = ({textList}) => (
  <div>
    {textList.map(t => (
      <CourseOutlineBulletPoint key={t} text={t} />
    ))}
  </div>
);

const CourseOutlineItems = [
  {
    key: "day_1",
    question: "Day 1: Intro To The Embodied CEO Method",
    answer: "In this training, I’ll share the step-by-step formula for creating embodied success. By the end of this training you’ll realize exactly why those goals haven’t turned up yet…and what you get to do about it."
  },
  {
    key: "day_2",
    question: "Day 2: Surrendering To Flow",
    answer: "In this training, you’re going to complete an audit of your life. You’ll learn how to quickly identify the kinks in your hose before you turn up the tap so you’ll never feel stuck for weeks on end again."
  },
  {
    key: "day_3",
    question: "Day 3: Success, From The Bottom Up",
    answer: "In this training, I’ll cover the importance of nervous system regulation, and why being highly connected with your body plays such an important role in manifesting soul-aligned success."
  },
  {
    key: "day_4",
    question: "Day 4: Your Emotions Are Your Superpower.",
    answer: "Here I’ll cover how to end the emotional rollercoasters, quickly identify the emotions getting in the way of your next manifestation and how to release them somatically from the body. Learn my favorite somatic exercises and apply them immediately."
  },
  {
    key: "day_5",
    question: "Day 5: The Paradigm Shift",
    answer: "In this training, you’ll learn how to unearth the hidden subconscious blocks that get in the way of your goals -- I use this process every single time I want to create rapid changes in my life."
  },
  {
    key: "day_6",
    question: "Day 6: Raise Your Standards, Catapult Your Life",
    answer: "Learn the process for upleveling your standards in a way that feels “safe” to your subconscious mind and nervous system. Get inspired by a list of examples that you can build on and implement immediately. "
  },
  {
    key: "day_7",
    question: "Day 7: Replace Skepticism With Discernment",
    answer: "The Truths that no one tells you about creating rapid and lasting change in your life. Understand the subtle difference between skepticism and discernment, how skepticism keeps you stuck, and why discernment must take its place to manifest at high speeds."
  },
  {
    key: "day_8",
    question: "Day 8: The Dance of Feminine Boundaries",
    answer: "Learn of art of “feminine boundaries”. In this training, you’ll discover how to say YES to yourself and heal subconscious betrayal that’s been keeping you stuck where you are once and for all – and why feminine boundaries must replace masculine rigidity in order to stay in flow."
  },
  {
    key: "day_9",
    question: "Day 9: Release Control, Release Your Money Blocks",
    answer: "Understand the neurobiology of safety and the role it plays in manifesting the life and wealth you desire. In this training, I’ll cover techniques and practices to support you as you navigate big shifts and changes in your life."
  },
  {
    key: "day_10",
    question: "Day 10: The 4 Truths of the Embodied CEO",
    answer: "Learn the rarely taught truths that every Embodied CEO understands and become laser-sharp in your discernment and intuition with this step-by-step framework for discerning Truth from non-truth."
  },
  {
    key: "day_11",
    question: "Day 11: Flowing With Divine Inspiration",
    answer: "In this training, I’ll share what true “aligned action” gets to look like for you as you move through the world. You’ll learn the pitfalls where most people get stuck and how you can quickly get back into alignment."
  },
  {
    key: "day_12",
    question: "Day 12: The Emotional Contagion Effect",
    answer: "Understand the importance of the social nervous system, how to harness it to your advantage, and how to avoid absorbing the frustration, self-sabotage, and burnout of others."
  },
  {
    key: "day_13",
    question: "Day 13: Suspending Disbelief and the “Time Delay”",
    answer: "Master the “time delay” and end self-sabotage. You’ll learn how to stay patient, focused and trusting while you’re waiting for your goals to come to life."
  },
  {
    key: "day_14",
    question: "Day 14: Passing the “Worthiness” Tests",
    answer: "In this final training, you’ll learn how to step into greater worthiness, compound your results, let go of self-doubt and fly through any tests sent your way just before your goal and vision reveals itself."
  }
]

const FAQItems = [
  {
    key: "drip_fed",
    question: "How does program access work?",
    answer: "After enrolling, you'll receive one lesson each day for 14 days. The lessons in this program build on each other, and this approach allows each individual learning to sink in separately."
  },
  {
    key: "program_access",
    question: "How long do I have access to this program?",
    answer: "To ensure that you implement what's taught, you'll receive 6 months of program access."
  },
  {
    key: "business_owner",
    question: "I'm not a business owner - does this program still apply to me?",
    answer: "Yes - this program is designed for anyone that wants to become the Embodied CEO of their life."
  }
]

let defaultCourseItemsShowDetail = {}
CourseOutlineItems.map(key => defaultCourseItemsShowDetail[key] = false)

let defaultFaqItemsShowDetail = {}
FAQItems.map(key => defaultFaqItemsShowDetail[key] = false)

class LoveLandingPage extends React.Component {
  constructor(props){
    super(props)

    const innerHeight = document.body.clientHeight || window.innerHeight
    document.body.height = innerHeight

    this.state = {
      headerColor: "transparent",
      dropShadowColor: "rgba(0, 0, 0, 0.05)",
      lgcyBorderColor: "transparent",
      topPageScrollProgress: 0,
      show: true,
      showCourseDetail: defaultCourseItemsShowDetail,
      showFaqDetail: defaultFaqItemsShowDetail,
      selectedVideo: {},
      hasScrolled: false,
      innerHeight: innerHeight,
    }
  }


  updateInnerHeight() {
    const innerHeight = document.body.clientHeight || window.innerHeight
    document.body.height = innerHeight
    this.setState({innerHeight})
  }
  componentWillMount() {
    this.updateInnerHeight()
  }

  componentDidMount() {
    hideLoadingScreen()
    window.addEventListener('resize', () => {
      this.updateInnerHeight()
    })

    this.updateInnerHeight()


    window.addEventListener("pageshow", () => {
      this.updateInnerHeight()
    })

    // Check for frontend updates whenever app is shown.
    document.addEventListener("visibilitychange", e => {
      const pageIsVisible = (!document.hidden)
      this.setState({pageIsVisible: false})
      this.updateInnerHeight()
    })

    smoothscroll.polyfill();

    safeAreaInsets.onChange(updatedValue => this.setState({safeAreaInsets}))
  }

  adjustBgPositionById = (id, scrollTop, innerHeight, xvalue="center", bgImage=undefined) => {
    let target = document.getElementById(id)

    let factor = 0.2
    let yvalue = (target.offsetTop - scrollTop) * factor
    let yvalueSign = `${(yvalue > 0 ? "-" : "")}`

    if (!!(bgImage)) {
      target.style.backgroundImage = bgImage
    }

    if (isIOSDevice) {
      target.style.backgroundAttachment = "scroll"
      target.style.backgroundPosition = `${xvalue} bottom`
      let opacityScore = 1.3 - (Math.abs(target.offsetTop - scrollTop) / (innerHeight * 0.6))
      target.style.opacity = Math.min(Math.max(0.0, opacityScore), 1.0)
    } else {
      target.style.backgroundPosition = `${xvalue} ${yvalue}px`
    }
  }

  updateColorsOnContainerScroll = e => {
    const {innerHeight} = this.state

    const scrollTop = e.target.scrollTop
    this.adjustBgPositionById("lp-bg-image-container", scrollTop, innerHeight)
    // this.adjustBgPositionById("lp-bg-image-container-coaches", scrollTop, innerHeight, "center", "url(/images/creators/lorenxandrew-coaches.webp), linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)")
    this.adjustBgPositionById("lp-bg-image-container-loren", scrollTop, innerHeight, "left", "url(/images/creators/loren-coach.webp), linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)")
    // this.adjustBgPositionById("lp-bg-image-container-andrew", scrollTop, innerHeight, "right", "url(/images/creators/andrew-coach.webp), linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)")

    const hasScrolled = true

    if (!this.state.hasScrolled) {
      this.setState({hasScrolled})
      initializePixelIfAvailable()
    }
    if (scrollTop > 0) {
      const alpha = Math.min(0.5, (scrollTop / 500))
      const headerColor = `rgba(255, 255, 255, ${alpha*2.0})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/2.0})`
      const topPageScrollProgress = Math.min(Math.max((e.target.scrollTop / innerHeight - 0.5) * 2.0, 0.0), 1.5)
      const lgcyBorderColor = `rgba(0, 0, 0, ${alpha*2.0})`
      this.setState({headerColor, hasScrolled,  dropShadowColor, topPageScrollProgress, lgcyBorderColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", hasScrolled, dropShadowColor: "transparent", topPageScrollProgress: 0, lgcyBorderColor: "transparent"})
    }
  }


  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateColorsOnContainerScroll)
    recordVibeCheckFunnelEventGA(GA_LABEL_LOADED_LANDING_PAGE)
  }

  joinNowButtonClick = () => {
    this.learnMoreRef.scrollIntoView({behavior: "smooth"})
  }

  viewTrainingButtonClick = () => {
    window.open(MasterclassVideoUrl, "_blank", "noopener noreferrer")
  }

  viewFAQPageButtonClick = () => {
    window.location = "/faq"
  }

  render() {

    const {show, innerHeight, hasScrolled, scrollingContainerRef, showCourseDetail, showFaqDetail, headerColor, topPageScrollProgress, lgcyBorderColor, dropShadowColor, selectedVideo} = this.state

    const dropShadow = range(4).map(() => (`0px 0px 3px ${dropShadowColor}`)).join(",")


    const baseSvgDropShadow = `0px 0px 1px ${!!(dropShadowColor) ? dropShadowColor : "#353F6A"}`
    const textShadow = range(4).map(() => (`0px 0px 5px rgba(0, 0, 0, 0.16)`)).join(",")
    const shareSvgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.16))`)).join(" ")
    const svgDropShadow = range(2).map(() => (`drop-shadow(${baseSvgDropShadow})`)).join(" ")
    const displaySvgDropShadow = range(4).map(() => (`drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1))`)).join(" ")
    const displayTextShadow = range(4).map(() => (`0px 0px 2px rgba(0, 0, 0, 0.12)`)).join(",")

    const atPageTop = (topPageScrollProgress === 0) && false

    const showApplyButton = true

    return (
      <CSSTransition
        in={show}
        timeout={500}
        classNames={"fade-in-500"}
        onExited={() => {}}
      >
        <div style={{position: "fixed", left: 0, top: 0, width: "100vw", background: "linear-gradient(0deg, #616161 0%, #919191 33%, #C1C1C1 67%, #F1F1F1 100%)", height: innerHeight, overflow: "hidden"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: 30, backgroundColor: headerColor, display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15, borderBottom: `2px ${lgcyBorderColor} solid`, zIndex: 1000}}>
            <div>
              <LoadingLogo
                show={true}
                hideAnimation={true}
                hideLogo
                responsiveSize={"min(5vw, 25px)"}
                responsiveLogoSize={"min(5vw, 25px)"}
                padding={"0px"}
                marginTop={0}
                color={"black"}
                fontFamily={"Futura Book"}
                text={"EMBODIED CEO"}
                showProgressionBar={false}
                onClick={() => window.location = "/"}
              />
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <a
                style={{height: "min(6vw, 30px)", width: "min(5vw, 25px)", cursor: "pointer", marginRight: "min(3vw, 15px)"}}
                href={NeuroFitHomePageUrl}
                target={"_blank"}
                rel={"noreferrer noopener"}
                alt={"NeuroFit App Homepage"}
                title={"NeuroFit App Homepage"}
                onClick={() => {
                  recordLinkClickGA("NeuroFit Homepage")
                }}
              >
                <NeuroFitLogoSvg
                  size={"min(5vw, 25px)"}
                  color={"#000000"}
                  lineColor={"#FFFFFF"}
                  margin={"0.1em 0px 0px 0px"}
                />
              </a>
              <div
                style={{width: "min(5vw, 25px)", marginTop: 4, cursor: "pointer"}}
                onClick={() => {
                  recordLinkClickGA("LGCY Instagram")
                  window.open(LorenInstagramAccountUrl, "_blank", "noopener noreferrer")
                }}
              >
                <svg className="authentique-svg" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="black" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
              </div>
            </div>
          </div>
          <div 
            className={"disable-scrollbars"}
            style={containerStyle}
            ref={elem => this.scrollingContainerRef = elem}
          >
            <div id="lp-bg-image-container" style={{textAlign: "left", height: "100%", width: "100vw"}}>
              <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div>
                    <div className="lp-header" style={{textShadow: textShadow, marginTop: 0.55 * innerHeight, color: "#fff", fontSize: "min(11vw, 45px)"}}>
                      {"EMBODIED CEO"}
                    </div>
                    <div style={{textAlign: "left", fontFamily: "Futura Book", textShadow: textShadow, fontSize: "min(6vw, 24px)", color: "#fff", wordBreak: "break-word", hyphens: "none"}}>
                      {"Helping intuitive entrepreneurs embrace their God-given gifts, manifest more money, and embody soul-aligned success."}
                    </div>
                    <div style={{textAlign: "left", marginTop: "min(20px, 5vh)"}}>
                      <button
                        onClick={() => this.joinNowButtonClick()} style={{display: "inline-block", textUnderlineOffset: 4, textDecorationThickness: 1, fontFamily: "Futura Book", fontSize: "min(5.5vw, 22px)", color: "black", backgroundColor: "white", padding: "min(4vw, 15px) min(4.5vw, 20px)", marginRight: "min(4.5vw, 20px)", border: "none", backdropFilter: "blur(10px)", WebkitBackdropFilter: "blur(10px)"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <div style={{display: "inline-block"}}>
                            {"Explore Program".toUpperCase()}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "left", width: "100vw"}} ref={elem => this.learnMoreRef = elem}>
              <div style={{backgroundColor: "#FFFFFF", color: "black", padding: "10vh 0px 8vh 0px", borderTop: "solid black 2px"}} ref={elem => this.workshopsRef = elem}>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div className="lp-header" style={{fontSize: "min(5.5w, 22px)", fontStyle: "italic", marginTop: 40}}>
                      {[
                        "Dear Intuitive Business Owner,",
                        "Do you ever feel as though you’re yet to receive the full recognition that you deserve for all that you do in the world? And that you’ve been searching for some important missing piece of the puzzle that no one seems to have the answers to?",
                        "And do you have this deep knowing in your soul that you’re here to do more than what you’re doing now - but not according to the “linear” rules of this world?",
                        "If so, I designed the Embodied CEO just for you.",
                        "I have lovingly curated a deeply transformative 14-day journey for you that dives deep into the fundamentals of true, soul-aligned and embodied success. You can apply this wisdom to any area of your life, and come back to this program time-and-time again.",
                        "It’s the program I wish someone had given me 12 years ago when I embarked on this journey, because it would have saved me many years of frustration, tears, heartache and hundreds of thousands invested searching for answers.",
                        <span>{"My goal is for you to experience profound paradigm shifts as you move through this program on a mind, body and soul level, so that you emerge as the innovator you know you’re here to be - "}<span style={{fontFamily: "Futura Book"}}>{"the Embodied CEO"}</span>{"."}</span>,
                        "With love,",
                        <img src={hasScrolled ? "/images/loren-signature.webp" : ""}  style={{width: 200}} alt={"Loren Hogue Signature"} />
                      ].map((text, idx) => {
                        return (
                          <div style={{fontSize: "min(5.5vw, 22px)", fontFamily: "Futura Light", margin: "1.5vh 0px"}} key={idx}>
                            {text}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "left", width: "100vw"}}>
              <div style={{backgroundColor: "#F1F1F1", color: "black", padding: "10vh 0px 8vh 0px", borderTop: "solid black 2px", borderBottom: "solid black 2px"}} ref={elem => this.workshopsRef = elem}>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                  <div style={{width: "85%", maxWidth: 700}}>
                    <div className="lp-header" style={{marginTop: "max(6vh, 40px)"}}>
                      {"EMBODIED CEO™"}
                    </div>
                    <div className="lp-header">
                      {"PROGRAM DETAILS"}
                    </div>
                    {CourseOutlineItems.map(f => (
                      <FaqSection
                        key={f.key}
                        question={f.question}
                        answer={f.answer}
                        italicAnswer
                        showAnswer={showCourseDetail[f.key]}
                        onToggleShowAnswer={() => this.setState({showCourseDetail: {...showCourseDetail, [f.key]: (!showCourseDetail[f.key])}})}
                        marginTop={"2vh"}
                      />
                    ))}                    
                  </div>
                </div>
              </div>
            </div>
            <div id="lp-bg-image-container-loren" style={{textAlign: "left", height: "100%", width: "100vw"}} ref={elem => this.meetLorenRef = elem}>
              <div style={{color: "white", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "90%", maxWidth: 700}}>
                  <div>
                    <div className="lp-header" style={{marginTop: 0.45 * innerHeight, textShadow: textShadow, color: "#fff"}}>
                      {"MEET YOUR COACH:".toUpperCase()/*Resilience, wisdom, compassion, clarity, creativity.*/}
                    </div>
                    <div className="lp-header" style={{textShadow: textShadow, color: "#fff"}}>
                      {"LOREN HOGUE".toUpperCase()/*Resilience, wisdom, compassion, clarity, creativity.*/}
                    </div>
                    <div style={{textAlign: "left", fontFamily: "Futura Book", textShadow: textShadow, fontSize: "min(6vw, 24px)", color: "#fff"}}>
                      <span>{"Loren is a multi 7-figure Master Coach, with 3000+ hours of experience serving 1500+ clients. She's also Co-CEO of "}</span>
                      <a style={{fontFamily: "Futura Book", textShadow: textShadow, fontSize: "min(6vw, 24px)", textUnderlineOffset: 2, textDecoration: "underline", color: "#fff"}} href="https://neurofit.app" target="_blank" rel="noreferrer noopener">{"NeuroFit"}</a>
                      <span>{" - the world's leading nervous system regulation app."}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{backgroundColor: "#F1F1F1", padding: "calc(5vh + 60px) 0px", borderTop: "solid black 2px"}} ref={elem => this.checkoutRef = elem}>
              <div style={{color: "#fff", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 500, color: "#000"}}>
                  <div className="lp-header" style={{color: "#000000"}}>
                    {"ENROLL NOW"}
                  </div>
                  {hasScrolled && (
                    <StripeCheckoutForm
                      onShowSuccessMessage={(successMessage) => {}}
                      onShowErrorMessage={(errorMessage) => {}}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{backgroundColor: "#000000", paddingTop: "10vh", borderTop: "solid black 2px"}}>
              <div style={{color: "#fff", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700, color: "#000"}}>
                  <div className="lp-header" style={{color: "#ffffff"}}>
                    {"FAQ"}
                  </div>
                  {FAQItems.map(f => (
                    <FaqSection
                      key={f.key}
                      question={f.question}
                      answer={f.answer}
                      italicAnswer
                      showAnswer={showFaqDetail[f.key]}
                      onToggleShowAnswer={() => this.setState({showFaqDetail: {...showFaqDetail, [f.key]: (!showFaqDetail[f.key])}})}
                      marginTop={"2vh"}
                    />
                  ))}
                </div>
              </div>
              <div style={{textAlign: "left", width: "100vw", paddingTop: "10vh"}}>
                <div style={{padding: "5px 15px 10px 15px", fontSize: "min(3.5vw, 16px)", fontFamily: "Futura Book", color: "#999999", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                  <div style={{display: "inline-block"}}>
                    {"© 2023 NEUROFIT"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    )
  }
}

export default LoveLandingPage;