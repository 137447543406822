import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';

import { polyfill } from "seamless-scroll-polyfill";

import LoveLandingPage from '../components/LoveLandingPage';

import { 
  initializeGA,
  pageViewGA,
  subdomainHosts,
} from '../utils';

import {
  REQUEST_SUCCESS,
  REQUEST_ERROR,
} from '../constants/requestStates'


require('../style.css');

polyfill();

class App extends React.Component {

  componentWillMount() {
    initializeGA();
    pageViewGA();
  }

  render() {
    return (
      <LoveLandingPage />
    );
  }
}

export default App
